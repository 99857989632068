import { useForm } from 'react-hook-form';
import { Card, Stack, Typography } from '@mui/material';
import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../lib/hooks/useAuth';
import { FormProvider, RHFTextField } from '../../shared/hook-form';
import { PATH_AFTER_LOGIN } from '#/config';
import useLocales from '#/hooks/useLocales';

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const redirectLink = searchParams.get('redirectLink');

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(String(translate('validations.user.email'))),
    password: Yup.string().required(
      String(translate('validations.user.password'))
    ),
  });

  const defaultValues = {
    email: import.meta.env.VITE_LOGIN_EMAIL || '',
    password: import.meta.env.VITE_LOGIN_PASSWORD || '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    const toastId = toast.loading('Signin in...');

    try {
      setIsLoading(true);
      const body = {
        email: data.email.toLowerCase(),
        password: data.password,
      };

      const serviceRequest = redirectLink
        ? `/dashboard/${redirectLink}`
        : undefined;

      const path_after_login = serviceRequest || PATH_AFTER_LOGIN;

      login(body);
      setIsLoading(false);
      navigate(pathname === '/auth/login' ? path_after_login : pathname);
      toast.dismiss(toastId);
    } catch (error) {
      setIsLoading(false);
      toast.error(String(translate('toast_notifications.error.login')));
      toast.dismiss(toastId);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          padding: '2rem',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom>
          {String(translate('global.dashCardInfos.getStarted'))}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            marginY: 3,
          }}
        >
          {String(translate('global.loginInfo.newUser'))}&nbsp;
          <Link to="/vorsorge">
            {String(translate('global.loginInfo.createAccount'))}
          </Link>
        </Typography>
        <Stack spacing={3}>
          <RHFTextField
            name="email"
            label={String(translate('global.formLabels.emailAddress'))}
          />
          <RHFTextField
            name="password"
            autoComplete="password"
            label={String(translate('global.formLabels.password'))}
            type="password"
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Link to="/auth/reset-passwort">
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                my: 2,
                mb: 4,
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {String(translate('global.loginInfo.forgotPassword'))}
            </Typography>
          </Link>
        </Stack>
        <LoadingButton
          aria-label={String(translate('global.loginInfo.login'))}
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ borderRadius: '8px' }}
          loading={isLoading}
        >
          <Typography variant="body1">
            {String(translate('global.loginInfo.login'))}
          </Typography>
        </LoadingButton>
      </Card>
    </FormProvider>
  );
};

export default LoginForm;
