import { styled } from '@mui/material/styles';

export const StyledRoot = styled('main')(() => ({
  backgroundImage: `url('/assets/images/backgrounds/login-bg.webp')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, #0E2A47 0%, rgba(14, 42, 71, 0) 140%)',
  },
}));

export const StyledContent = styled('div')(({ theme }) => ({
  margin: '0 auto',
  height: '100%',

  padding: theme.spacing(15, 2),
  [theme.breakpoints.up('md')]: {
    width: 620,
    flexShrink: 0,
    // padding: theme.spacing(30, 8, 0, 8),
  },
}));
