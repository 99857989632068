import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from '#/utils/axios';
import { JWTContextType, AuthUser, ActionMap, AuthState } from '#/types/auth';
import { isValidToken, setSession } from '#/utils/jwt';
import queryClient from '#/api/query-client';
import useLocales from '#/hooks/useLocales';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const { translate } = useLocales();
  const navigate = useNavigate();

  const initialize = async (accessToken?: string) => {
    try {
      const access_token = localStorage.getItem('accessToken') || accessToken;
      setIsLoading(true);
      if (access_token && isValidToken(access_token)) {
        setSession(access_token);
        const user = await axios.get('/users/profile/');
        const userDetails: AuthUser = user.data;
        setIsLoading(false);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: userDetails,
          },
        });
      } else {
        setIsLoading(false);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        localStorage.removeItem('accessToken');
      }
    } catch (error: any) {
      setIsLoading(false);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      toast.error(error.message);
    }
  };

  useEffect(() => {
    initialize().then(() => {});
  }, []);

  const login = useMemo(
    () => async (params: any) => {
      setIsLoading(true);
      const response = await axios.post('/users/login/', params);

      const { access, OTP } = response.data;
      setIsLoading(false);

      if (OTP) {
        navigate('/auth/verifizieren', { state: { email: params.email } });
      } else {
        setSession(access);
        const userDetails = await axios.get('/users/profile/');
        dispatch({
          type: Types.Login,
          payload: {
            user: userDetails?.data,
          },
        });
      }
      toast.success(String(translate('toast_notifications.success.login')));
    },
    []
  );

  const loginFromToken = useMemo(
    () => async (params: any, baseUrl?: string) => {
      const user = await axios.post(
        `/${baseUrl ?? 'successor'}/accept-invitation/`,
        {
          token: params,
        }
      );

      const { token } = user.data;
      setSession(token);

      const userDetails = await axios.get('/users/profile/');

      dispatch({
        type: Types.Login,
        payload: {
          user: userDetails?.data,
        },
      });
      toast.success(String(translate('toast_notifications.success.login')));
    },
    // eslint-disable-next-line
    []
  );

  const register = useMemo(
    () => async (params: any) => {
      setIsLoading(true);
      const user = await axios.post('/users/register/', params);

      const { token } = user.data;
      setSession(token);
      setIsLoading(false);

      const userDetails = await axios.get('/users/profile/');

      dispatch({
        type: Types.Register,
        payload: {
          user: userDetails?.data,
        },
      });
      toast.success(String(translate('toast_notifications.success.register')));
    },
    // eslint-disable-next-line
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = async () => {
    setSession(null);
    localStorage.removeItem('guest_on');
    localStorage.removeItem('ownerID');
    dispatch({ type: Types.Logout });
    queryClient.clear();
    toast.success(String(translate('toast_notifications.error.login')));
  };

  const refetch = async () => {
    const user = await axios.get('/users/profile/');
    const userDetails: AuthUser = user.data;
    dispatch({
      type: Types.Login,
      payload: {
        user: userDetails,
      },
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      isLoading,
      login,
      loginFromToken,
      initialize,
      logout,
      register,
      refetch,
    }),
    [state, login, logout, loginFromToken, register]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
