import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import useAuth from '#/hooks/useAuth';
import { AuthUser, User } from '#/types/auth';
import { useListMyConnections, useSwitchAsGuest } from '#/api/successor';

type AccountSwitchContextProps = {
  currentAccount: CurrentAccount | AuthUser | undefined;
  handleSwitchAccount: (account: AuthUser) => void;
  refetchAccount: (id: number) => void;
  isLoading?: boolean;
};

const AccountSwitchContext = createContext<AccountSwitchContextProps>({
  currentAccount: undefined,
  handleSwitchAccount: () => {},
  refetchAccount: () => {},
  isLoading: false,
});

type AccountSwitchProviderProps = {
  children: React.ReactNode;
};

export interface CurrentAccount extends User {
  docs: {
    reported_as_dead: boolean;
    downloaded: [];
    living_will: object;
    power_of_attorney: object;
    last_will: object;
  };
}

const AccountSwitchProvider: React.FC<AccountSwitchProviderProps> = ({
  children,
}) => {
  const [currentAccount, setCurrentAccount] = React.useState<
    CurrentAccount | AuthUser
  >();
  const [enableList, setEnableList] = React.useState<boolean>(false);
  const { user, isInitialized } = useAuth();
  const { mutateAsync: switchAsGuest } = useSwitchAsGuest();
  const { data: connectionsList, isLoading } = useListMyConnections(enableList);

  useEffect(() => {
    const guestOn = localStorage.getItem('guest_on');
    if (guestOn) {
      switchAsGuest({ id: Number(guestOn) }).then((res) => {
        setCurrentAccount(res?.data);
      });
    }
    setCurrentAccount(user);
    // eslint-disable-next-line
  }, []);

  const handleSwitchAccount = useCallback((account: any) => {
    localStorage.setItem('guest_on', JSON.stringify(account?.id));
    setCurrentAccount(account);
  }, []);

  const refetchAccount = useCallback(async (id: number) => {
    await switchAsGuest({ id }).then((res) => {
      setCurrentAccount(res?.data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && isInitialized) {
      setEnableList(true);
    }
    const guestOn = localStorage.getItem('guest_on');
    if (user && user?.primary_account && guestOn === null) {
      setCurrentAccount(user);
      localStorage.removeItem('guest_on');
      localStorage.removeItem('ownerID');
    } else if (connectionsList && guestOn !== null) {
      switchAsGuest({ id: Number(guestOn) }).then((res) => {
        setCurrentAccount(res?.data);
      });
    } else if (connectionsList?.length !== 0 && guestOn === null) {
      if (connectionsList) {
        switchAsGuest({ id: Number(connectionsList[0]?.id) }).then((res) => {
          setCurrentAccount(res?.data);
          localStorage.setItem('guest_on', JSON.stringify(res?.data?.id));
        });
      }
    }
  }, [user, connectionsList, isInitialized]);

  const values = useMemo(
    () => ({
      currentAccount,
      handleSwitchAccount,
      refetchAccount,
      isLoading,
    }),
    [currentAccount, handleSwitchAccount, refetchAccount, isLoading]
  );

  return (
    <AccountSwitchContext.Provider value={values}>
      {children}
    </AccountSwitchContext.Provider>
  );
};

export { AccountSwitchProvider, AccountSwitchContext };
